<template>
    <div>
        <div class="flex space-x-4 items-center">
            <el-popover placement="bottom-start" width="260" trigger="click">
                <div>
                    <div v-if="data_list.length" class="w-full overflow-hidden">
                        <div class="px-4 py-2">
                            <el-input
                                v-model="string_search"
                                size="small"
                                :clearable="true"
                                :placeholder="
                                    $t('input.placeholder.please_input')
                                "
                                @clear="handleSearchAdsAccount"
                            >
                                <el-button
                                    slot="append"
                                    icon="el-icon-search"
                                    @click="handleSearchAdsAccount"
                                ></el-button>
                            </el-input>

                            <div class="flex mt-2">
                                <el-button
                                    size="mini"
                                    :disabled="
                                        selected_ads_accounts.length === 0
                                    "
                                    class="ml-auto"
                                    @click="handleSave"
                                >
                                    {{ $t('common.save') }}
                                </el-button>
                                <el-button
                                    size="mini"
                                    class="ml-auto"
                                    @click="handleSelectAll"
                                >
                                    {{
                                        selected_ads_accounts.length ===
                                        data_list.length
                                            ? $t('common.deselect_all')
                                            : $t('button.select_all')
                                    }}
                                </el-button>
                            </div>
                        </div>
                        <div
                            class="min-h-[160px] max-h-[320px] overflow-auto w-full"
                        >
                            <div
                                v-for="item in data_list"
                                :key="item.advertiser_id"
                                class="hover:bg-brand-atosa--hover relative px-4 py-1 cursor-pointer"
                                @click="handleSelected(item)"
                            >
                                <div class="flex flex-col">
                                    <span
                                        class="text-xs font-semibold leading-5"
                                    >
                                        {{ item.name }}
                                    </span>

                                    <div class="flex space-x-1">
                                        <i class="el-icon-money"></i>

                                        <span class="text-xs">
                                            {{
                                                p__renderMoneyByCurrency(
                                                    item.balance,
                                                    item.currency
                                                )
                                            }}
                                        </span>

                                        <el-checkbox
                                            class="!ml-auto"
                                            :value="
                                                selected_ads_accounts.some(
                                                    (it) =>
                                                        it.advertiser_id ===
                                                        item.advertiser_id
                                                )
                                            "
                                            @change="handleSelected(item)"
                                        ></el-checkbox>
                                    </div>
                                    <div class="flex items-center">
                                        <span class="component-text-desc">
                                            #{{ item.advertiser_id }}
                                        </span>

                                        <div
                                            v-if="item.belong_to_atosa"
                                            class="bg-brand-atosa--light util-flex-center h-5 px-1 ml-auto text-white"
                                        >
                                            <span class="text-xxxs uppercase">
                                                atosa
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <NoData v-else class="w-full h-[280px]"></NoData>
                </div>

                <el-button
                    slot="reference"
                    icon="el-icon-caret-bottom"
                    size="small"
                >
                    {{ $t('common.advertiser_account') }} ({{
                        selected_ads_accounts.length
                            ? `+${selected_ads_accounts.length}`
                            : '0'
                    }})
                </el-button>
            </el-popover>

            <el-tag type="success">
                {{ $t('common.total_balance') }}: <b>{{ total_balance }}</b>
            </el-tag>
        </div>

        <!-- <General
            class="mt-4"
            :tiktok_account="m__selected_tiktok_business"
            :ads_accounts="selected_ads_accounts"
        ></General> -->

        <Overview
            class="mt-6"
            :tiktok_account="m__selected_tiktok_business"
            :ads_accounts="selected_ads_accounts"
        ></Overview>
    </div>
</template>

<script>
import selectedTiktokBusinessMixin from '@/plugins/mixins/selected-tiktok-business'
import Overview from './overview'
import General from './general'

export default {
    components: {
        Overview
        // General
    },

    mixins: [selectedTiktokBusinessMixin],

    data() {
        return {
            total_balance: 0,
            data_list: [],
            string_search: '',
            selected_ads_accounts: []
        }
    },

    watch: {
        m__selected_tiktok_business() {
            this.m__fetchAdsAccountsByTiktokBusiness()
        },

        selected_ads_accounts() {
            const total = this.selected_ads_accounts.reduce((acc, current) => {
                acc += current.balance

                return acc
            }, 0)
            this.total_balance = this.p__renderMoneyByCurrency(
                total,
                this.selected_ads_accounts[0].currency
            )
        },

        m__ads_accounts() {
            if (this.m__ads_accounts.length > 0) {
                this.selected_ads_accounts = []

                const id_list = localStorage.getItem(
                    'dashboard_selected_ad_acc_ids' +
                        this.m__selected_tiktok_business.id
                )
                if (id_list) {
                    const arr_id = id_list.split(',')
                    for (const advertiser_id of arr_id) {
                        const item = this.m__ads_accounts.find(
                            (it) => it.advertiser_id === advertiser_id
                        )

                        if (item) {
                            this.selected_ads_accounts.push(item)
                        }
                    }
                }

                if (this.selected_ads_accounts.length === 0) {
                    this.selected_ads_accounts = [this.m__ads_accounts[0]]
                }
            } else {
                this.selected_ads_accounts = []
            }

            // if (this.selected_ads_accounts.length) {
            //     const total = this.selected_ads_accounts.reduce(
            //         (acc, current) => {
            //             acc += current.balance

            //             return acc
            //         },
            //         0
            //     )
            //     this.total_balance = this.p__renderMoneyByCurrency(
            //         total,
            //         this.selected_ads_accounts[0].currency
            //     )
            // } else {
            //     this.total_balance = 0
            // }

            this.handleSearchAdsAccount()
        }
    },

    mounted() {
        this.m__fetchAdsAccountsByTiktokBusiness()
    },

    methods: {
        handleSearchAdsAccount() {
            if (this.string_search) {
                this.data_list = this.m__ads_accounts.filter(
                    (item) =>
                        item.advertiser_id === this.string_search ||
                        item.name.match(new RegExp(this.string_search, 'i'))
                )

                return
            }

            this.data_list = this.m__ads_accounts
        },

        handleRemoveAdsAccount(advertiser_id) {
            this.selected_ads_accounts = this.selected_ads_accounts.filter(
                (item) => item.advertiser_id !== advertiser_id
            )
        },

        handleSelectAll() {
            if (
                this.selected_ads_accounts.length ===
                this.m__ads_accounts.length
            ) {
                this.selected_ads_accounts = []
            } else {
                this.selected_ads_accounts = this.m__ads_accounts
            }
        },

        handleSave() {
            if (this.selected_ads_accounts.length === 0) {
                return
            }

            localStorage.setItem(
                'dashboard_selected_ad_acc_ids' +
                    this.m__selected_tiktok_business.id,
                this.selected_ads_accounts
                    .map((item) => item.advertiser_id)
                    .join(',')
            )

            this.p__showNotify('success', this.$t('common.save_item_success'))
        },

        handleSelected(item) {
            if (
                this.selected_ads_accounts.some(
                    (it) => it.advertiser_id === item.advertiser_id
                )
            ) {
                this.handleRemoveAdsAccount(item.advertiser_id)
            } else {
                this.selected_ads_accounts.push(item)
            }
        }
    }
}
</script>

<template>
    <div>
        <div class="flex items-center mt-3 mb-2">
            <p class="text-sm font-semibold">
                {{ title }}
            </p>

            <div
                class="bg-bg p-2 ml-auto rounded-lg cursor-pointer"
                @click="handleExportExcel(new_data_list, ranger_date)"
            >
                <el-tooltip
                    class="item"
                    :content="$t(`common.export_excel`)"
                    effect="dark"
                    placement="top"
                >
                    <ExcelSvg></ExcelSvg>
                </el-tooltip>
            </div>
        </div>

        <div>
            <div class="relative">
                <el-table :height="360" :min-height="120" :data="new_data_list">
                    <el-table-column
                        width="160"
                        :show-overflow-tooltip="true"
                        :label="$t('common.daily')"
                        :fixed="true"
                    >
                        <template #default="scope">
                            <p>
                                {{
                                    scope.row.dimensions.stat_time_day
                                        | f__format_moment($t(format_date))
                                }}
                            </p>
                        </template>
                    </el-table-column>

                    <el-table-column
                        v-for="(item, index) in metrics"
                        :key="index"
                        width="160"
                        :show-overflow-tooltip="true"
                        :prop="item"
                        :label="$t(`page.campaign.column_${item}`)"
                        sortable="custom"
                        align="right"
                    >
                        <template v-slot:header>
                            <el-tooltip
                                class="item"
                                :content="$t(`page.campaign.column_${item}`)"
                                effect="dark"
                                placement="top"
                            >
                                <div slot="content" class="max-w-[360px]">
                                    <p
                                        class="text-sm"
                                        v-html="
                                            $t(`page.campaign.column_${item}`)
                                        "
                                    ></p>

                                    <p
                                        v-if="
                                            $t(
                                                `page.campaign.description_column_${item}`
                                            ) !==
                                            `page.campaign.description_column_${item}`
                                        "
                                        class="mt-1"
                                        v-html="
                                            $t(
                                                `page.campaign.description_column_${item}`
                                            )
                                        "
                                    ></p>
                                </div>

                                <p>
                                    {{ $t(`page.campaign.column_${item}`) }}
                                </p>
                            </el-tooltip>
                        </template>

                        <template #default="scope">
                            <p>
                                {{
                                    m__formatterColumn(
                                        scope.row.metrics[item],
                                        item,
                                        currency
                                    )
                                }}
                            </p>
                        </template>
                    </el-table-column>
                </el-table>

                <div
                    v-if="new_data_list.length === 0"
                    class="util-flex-center flex-col absolute top-[55%] left-1/2 -translate-x-1/2 -translate-y-1/2"
                >
                    <img
                        class="w-[140px] h-[140px]"
                        :src="require('@/assets/images/no_data.png')"
                    />
                    <p class="text-desc-text mt-2 text-sm">
                        {{ $t('common.no_data') }}
                    </p>
                </div>
            </div>

            <p v-if="switch_compare" class="mt-2 text-sm italic text-center">
                {{ ranger_date[0] | f__format_moment(format_date) }} -
                {{ ranger_date[1] | f__format_moment(format_date) }}
            </p>
        </div>

        <div v-if="switch_compare">
            <div class="relative mt-4">
                <div class="flex items-center mb-2">
                    <div
                        class="bg-bg p-2 ml-auto rounded-lg cursor-pointer"
                        @click="
                            handleExportExcel(
                                new_compare_data_list,
                                compare_ranger_date
                            )
                        "
                    >
                        <el-tooltip
                            class="item"
                            :content="$t(`common.export_excel`)"
                            effect="dark"
                            placement="top"
                        >
                            <ExcelSvg></ExcelSvg>
                        </el-tooltip>
                    </div>
                </div>

                <el-table
                    :height="360"
                    :min-height="120"
                    :data="new_compare_data_list"
                >
                    <el-table-column
                        width="160"
                        :show-overflow-tooltip="true"
                        :label="$t('common.daily')"
                        :fixed="true"
                    >
                        <template #default="scope">
                            <p>
                                {{
                                    scope.row.dimensions.stat_time_day
                                        | f__format_moment($t(format_date))
                                }}
                            </p>
                        </template>
                    </el-table-column>

                    <el-table-column
                        v-for="(item, index) in metrics"
                        :key="index"
                        width="160"
                        :show-overflow-tooltip="true"
                        :prop="item"
                        :label="$t(`page.campaign.column_${item}`)"
                        sortable="custom"
                        align="right"
                    >
                        <template v-slot:header>
                            <el-tooltip
                                class="item"
                                :content="$t(`page.campaign.column_${item}`)"
                                effect="dark"
                                placement="top"
                            >
                                <div slot="content" class="max-w-[360px]">
                                    <p
                                        class="text-sm"
                                        v-html="
                                            $t(`page.campaign.column_${item}`)
                                        "
                                    ></p>

                                    <p
                                        v-if="
                                            $t(
                                                `page.campaign.description_column_${item}`
                                            ) !==
                                            `page.campaign.description_column_${item}`
                                        "
                                        class="mt-1"
                                        v-html="
                                            $t(
                                                `page.campaign.description_column_${item}`
                                            )
                                        "
                                    ></p>
                                </div>

                                <p>
                                    {{ $t(`page.campaign.column_${item}`) }}
                                </p>
                            </el-tooltip>
                        </template>

                        <template #default="scope">
                            <p>
                                {{
                                    m__formatterColumn(
                                        scope.row.metrics[item],
                                        item,
                                        currency
                                    )
                                }}
                            </p>
                        </template>
                    </el-table-column>
                </el-table>

                <div
                    v-if="new_compare_data_list.length === 0"
                    class="util-flex-center flex-col absolute top-[55%] left-1/2 -translate-x-1/2 -translate-y-1/2"
                >
                    <img
                        class="w-[140px] h-[140px]"
                        :src="require('@/assets/images/no_data.png')"
                    />
                    <p class="text-desc-text mt-2 text-sm">
                        {{ $t('common.no_data') }}
                    </p>
                </div>
            </div>

            <p class="mt-2 text-sm italic text-center">
                {{
                    compare_ranger_date[0]
                        | f__format_moment(compare_format_date)
                }}
                -
                {{
                    compare_ranger_date[1]
                        | f__format_moment(compare_format_date)
                }}
            </p>
        </div>
    </div>
</template>

<script>
import campaignMixin from '@/views/campaign/campaign-wrapper/_mixin'
import ExcelSvg from '@/assets/svgs/excel.svg'
import { exportExcel } from '@/services/atosa-tiktok-ads/reporting'
import moment from 'moment'

export default {
    components: {
        ExcelSvg
    },

    mixins: [campaignMixin],

    props: [
        'advertiser_id',
        'data_list',
        'compare_data_list',
        'metrics',
        'currency',
        'switch_compare',
        'ranger_date',
        'compare_ranger_date',
        'format_date',
        'compare_format_date',
        'title'
    ],

    computed: {
        new_data_list() {
            const temp = [...this.data_list]
            temp.reverse()
            return temp
        },

        new_compare_data_list() {
            const temp = [...this.compare_data_list]
            temp.reverse()
            return temp
        }
    },

    methods: {
        async handleExportExcel(data_list, ranger_date) {
            const headers = [
                this.$t('common.daily'),
                ...this.metrics.map((item) =>
                    this.$t(`page.campaign.column_${item}`)
                )
            ]

            const body = data_list.map((item) => {
                return [
                    moment(item.dimensions.stat_time_day).format(
                        this.format_date
                    ),
                    ...this.metrics.map((me) =>
                        this.m__formatterColumn(
                            item.metrics[me],
                            item,
                            this.currency
                        )
                    )
                ]
            })

            const start_time = moment(ranger_date[0]).format(this.format_date)
            const end_time = moment(ranger_date[1]).format(this.format_date)

            const name = `daily_ad_${this.advertiser_id}_${start_time}_${end_time}`

            const response = await exportExcel({
                name,
                headers,
                body
            })

            this.p__exportExcelByBlob(response, name)
        }
    }
}
</script>
